<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    fullscreen
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_usergroup.userDetailDialog') }}
    </div>
    <div style="height: 100%;">
      <simple-table
        :table-column="tableColumn"
        :table-data="tableData"
        :pageable="false"
      >
        <simple-form
          v-model="searchModel"
          :form-field="searchFormField"
        >
          <template slot="tableOperation">
            <el-button @click="handleUserDataSearch">
              {{ $t('operation.search') }}
            </el-button>
            <el-button
              type="primary"
              @click="handleUserAddClick"
            >
              {{ $t('operation.create') }}
            </el-button>
          </template>
        </simple-form>
        <template
          slot="status"
          slot-scope="scope"
        >
          {{ $getDictLabel({type: 'data_status', value: scope.row.status}) }}
        </template>
        <template
          slot="operation"
          slot-scope="scope"
        >
          <el-button
            circle
            icon="el-icon-delete"
            :title="$t('operation.delete')"
            @click="deleteRecord(scope.row, scope.$index)"
          />
        </template>
      </simple-table>
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ $t('operation.cancel') }}
      </el-button>
      <el-button
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
    <sysuser-detail-dialog
      :show-flag.sync="isShowSysUserDetailDialog"
      :selection-data="tableData"
      @selection-change="selectionChange"
    />
  </el-dialog>
</template>
<script>
import sysuserDetailDialog from './sysuser-detail'
const BASEURL = {
  user: '/system/user/list',
  update: '/system/group/updateGroupUser'
}
export default {
  name: 'SystemUsergroupUserDetail',
  components: { sysuserDetailDialog },
  props: {
    showFlag: { type: Boolean, default: false },
    detailData: { type: Object }
  },
  data () {
    return {
      isShowSysUserDetailDialog: false,

      searchModel: {},
      tableData: []
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },

    searchFormField () {
      return [
        { prop: 'loginName', label: this.$t('system_user.loginName'), type: 'Input', component: { clearable: true } },
        { prop: 'name', label: this.$t('system_user.name'), type: 'Input', component: { clearable: true } },
        { slotName: 'tableOperation', col: { xs: 12, sm: 16, md: 12, style: { textAlign: 'right' } }, labelWidth: '0' }
      ]
    },
    tableColumn () {
      return [
        { prop: 'loginName', label: this.$t('system_user.loginName'), width: 150 },
        { prop: 'name', label: this.$t('system_user.name'), width: 120 },
        { prop: 'company.name', label: this.$t('system_user.companyName'), width: 180 },
        // { prop: 'phone', label: this.$t('system_user.phone'), width: 150 },
        { prop: 'status', label: this.$t('system_user.status'), width: 80, slotName: 'status' },
        { prop: 'remarks', label: this.$t('system_user.remarks') },

        { label: this.$t('field.operation'), slotName: 'operation', width: 60 }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },

    handleDataSubmit () {
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })

      this.$axios
        .post(BASEURL.update, { id: this.detailData.id, userList: this.tableData })
        .then(resp => {
          this.currentShowFlag = false
          this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
          this.handleDataSearch()
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    handleDialogOpen () {
      if (!this.detailData.id) {
        return
      }

      this.handleUserDataSearch()
    },

    selectionChange (selection) {
      this.tableData = selection
    },

    // 查询绑定的 User 列表
    handleUserDataSearch () {
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })

      this.$axios
        .post(BASEURL.user, { group: { id: this.detailData.id } })
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    // 新增绑定的人员
    handleUserAddClick () {
      this.isShowSysUserDetailDialog = true
    },

    deleteRecord (row, idx) {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.tableData.splice(idx, 1)
            done()
            instance.confirmButtonLoading = false
          } else done()
        }
      })
    },

    handleDialogClosed () {
      this.tableData.splice(0)
    }
  }
}
</script>
