<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    fullscreen
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_usergroup.sysuserDetailDialog') }}
    </div>
    <div style="height: 100%;">
      <simple-table
        ref="sysuserTable"
        with-selection
        reserve-selection
        :table-attrs="{ rowKey: 'id' }"
        :table-column="tableColumn"
        :table-data="tableData"
        :table-page-info="tablePageInfo"
        :table-data-count="tableDataTotalCount"
        @selection-change="handleSelectionChange"
        @page-info-change="handlePageInfoChange"
      >
        <simple-form
          v-model="searchModel"
          :form-field="searchFormField"
        >
          <template slot="tableOperation">
            <el-button @click="handleDataSearch">
              {{ $t('operation.search') }}
            </el-button>
          </template>
        </simple-form>
        <template
          slot="status"
          slot-scope="scope"
        >
          {{ $getDictLabel({type: 'data_status', value: scope.row.status}) }}
        </template>
      </simple-table>
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ $t('operation.cancel') }}
      </el-button>
      <el-button
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
/** 混入分页信息及数据总数信息 */
import pageMixins from '@/components/base/simple-table/mixin'
const BASEURL = {
  user: '/system/user/list'
}
export default {
  name: 'SystemUsergroupSysuserDetail',
  mixins: [pageMixins],
  props: {
    showFlag: { type: Boolean, default: false },
    selectionData: { type: Array, required: true }
  },
  data () {
    return {
      searchModel: {},
      tableData: [],

      currentSelection: []
    }
  },
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },

    searchFormField () {
      return [
        { prop: 'loginName', label: this.$t('system_user.loginName'), type: 'Input', component: { clearable: true } },
        { prop: 'name', label: this.$t('system_user.name'), type: 'Input', component: { clearable: true } },
        { slotName: 'tableOperation', col: { xs: 12, sm: 16, md: 12, style: { textAlign: 'right' } }, labelWidth: '0' }
      ]
    },

    tableColumn () {
      return [
        { prop: 'loginName', label: this.$t('system_user.loginName'), width: 150 },
        { prop: 'name', label: this.$t('system_user.name'), width: 120 },
        { prop: 'company.name', label: this.$t('system_user.companyName'), width: 180 },
        // { prop: 'phone', label: this.$t('system_user.phone'), width: 150 },
        { prop: 'status', label: this.$t('system_user.status'), width: 80, slotName: 'status' },
        { prop: 'remarks', label: this.$t('system_user.remarks') }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },

    handleDataSubmit () {
      this.$emit('selection-change', this.$_.uniqBy(this.currentSelection, 'id'))

      // 分页及各项数据初始化
      this.tablePageInfo = {
        pageNo: 1,
        pageSize: 10
      }
      this.tableDataTotalCount = 0

      this.currentShowFlag = false
    },

    handleDialogOpen () {
      this.handleDataSearch()
    },

    // 查询绑定的 User 列表
    handleDataSearch () {
      const params = Object.assign({}, { page: this.tablePageInfo }, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })

      this.$axios
        .post(BASEURL.user, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
          this.tablePageInfo = { pageNo: respData.pageNo, pageSize: respData.pageSize }
          this.tableDataTotalCount = respData.count

          this.selectionData.forEach(item => {
            this.$refs.sysuserTable.toggleRowSelection(item, true)
          })
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    handleSelectionChange (selection) {
      this.currentSelection = selection
    },

    handleDialogClosed () {
      this.$refs.sysuserTable.clearSelection()
      this.tableData.splice(0)
    }
  }
}
</script>
